import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, Fade, Flex, Grid, Text } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as LeftArrowIcon } from 'icons/back-arr.svg';

import Menu from './Menu';

const ProfileHeader: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const user = useAppSelector((store) => store.auth.user);
  let backLink = '/';

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Fade in={isMenuOpen} style={{ zIndex: 2 }} unmountOnExit>
        <Menu onClose={() => setIsMenuOpen(false)} user={user} />
      </Fade>

      <Grid
        gridTemplateColumns="48px 1fr 48px"
        gap="8px"
        py="10px"
        px="12px"
        pos="sticky"
        top="0"
        zIndex="1"
        w="100%"
        maxW="500px"
        margin="-8px auto 0"
        backgroundColor={isScrolled ? '#242424' : 'transparent'}
        transition="all 0.2s ease-in-out"
        mb="-78px"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            as={Link}
            to={backLink}
            height="48px"
            width="48px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="20px"
            borderRadius="12px"
            transition="all 0.2s ease-in-out"
            _active={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          >
            <Box as={LeftArrowIcon} />
          </Box>
        </Box>
        <Flex textAlign="center" justifyContent="center" alignItems="center">
          {isScrolled && (
            <Fade in>
              <Text
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                lineHeight="1.2"
                fontSize="16px"
                align="center"
              >
                {user?.username}
              </Text>
            </Fade>
          )}
        </Flex>
        <Flex
          cursor="pointer"
          fontSize="20px"
          lineHeight="1"
          borderRadius="12px"
          transition="all 0.2s ease-in-out"
          height="48px"
          width="48px"
          alignItems="center"
          justifyContent="center"
          gap="3px"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          _active={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <Box w="3px" h="3px" borderRadius="50%" backgroundColor="#fff" />
          <Box w="3px" h="3px" borderRadius="50%" backgroundColor="#fff" />
          <Box w="3px" h="3px" borderRadius="50%" backgroundColor="#fff" />
        </Flex>
      </Grid>
    </>
  );
};

export default ProfileHeader;

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, useToast } from '@chakra-ui/react';
import routes from 'routes';

import { useInviteUserMutation } from 'api/contest';

import { Loader } from 'components/Loader';

import { useAppSelector } from 'hooks/app';
import useInviteUser from 'hooks/useInviteUser';

const InvitePage = () => {
  const { code } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  useInviteUser({ code, onSuccessfulInvite: () => navigate(routes.home), onFailedInvite: () => {} });

  return (
    <>
      <Loader />
    </>
  );
};

export default InvitePage;

import { Link } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import routes from 'routes';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as RightArrowIcon } from 'icons/right-arr-bold.svg';

import Avatar from './Avatar';
import Stats from './Stats';

const UserInfo: React.FC = () => {
  const user = useAppSelector((store) => store.auth.user);
  const now = dayjs().tz('America/Los_Angeles');
  const userJoinAtNewYork = dayjs(user?.dateJoined).tz('America/Los_Angeles');

  const daysSinceJoined = now.diff(userJoinAtNewYork, 'day');

  if (!user) {
    return null;
  }

  return (
    <Flex
      width="100%"
      p="12px"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      direction="column"
      gap="7px"
      mb="14px"
    >
      <Flex w="100%" justifyContent="center" mb="8px">
        <Avatar user={user} />
      </Flex>
      <Flex direction="column" gap="8px" mb="17px">
        <Flex direction="column" gap="6px">
          <Text
            as={Link}
            to={routes.editName}
            state={{ from: location.pathname }}
            lineHeight="1.2"
            fontSize="20px"
            align="center"
          >
            {user.username}
          </Text>
          <Box fontWeight="800" opacity="0.8" lineHeight="1.2" fontSize="15px" textAlign="center">
            {user.bio.length > 0 ? (
              user.bio
            ) : (
              <Flex
                as={Link}
                to={routes.editBio}
                state={{ from: location.pathname }}
                align="center"
                gap="4px"
                justifyContent="center"
              >
                <Box as="span">add bio and socials</Box>
                <Box as={RightArrowIcon} pos="relative" top="1px" h="8px" w="8px" fontWeight="800" />
              </Flex>
            )}
          </Box>
        </Flex>
        <Text fontWeight="500" opacity="0.3" lineHeight="1.2" fontSize="13px" align="center">
          Joined {user.dateJoined} • {daysSinceJoined}d
        </Text>
      </Flex>
      <Stats user={user} />
    </Flex>
  );
};

export default UserInfo;

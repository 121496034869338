import { update } from 'cypress/types/lodash';
import { type User } from 'models';

import { api } from './api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/user/',
      }),
    }),
    updateUserName: builder.mutation<User, { username?: string }>({
      query: ({ username }) => {
        return {
          url: `/user/`,
          method: 'POST',
          body: { username },
        };
      },
    }),
    updateUserBio: builder.mutation<User, { bio?: string }>({
      query: ({ bio }) => {
        return {
          url: `/user/`,
          method: 'POST',
          body: { bio },
        };
      },
    }),
    updateUserAvatar: builder.mutation<User, { avatar: string | null }>({
      query: ({ avatar }) => {
        return {
          url: `/user/`,
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: { avatar },
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserNameMutation,
  useUpdateUserBioMutation,
  useUpdateUserAvatarMutation,
} = authApi;

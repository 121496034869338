import { FC, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Img } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest } from 'models';
import routes from 'routes';

import { useLazyGetContestParticipantQuery } from 'api/contest';

import { getEventStatusString } from './utils';

const SliderItem: FC<{ contest: Contest }> = ({ contest, ...props }) => {
  const navigate = useNavigate();
  const title = useMemo(() => {
    if (!contest) return '';
    const startsAtNewYork = dayjs(contest.startsAt).tz('America/Los_Angeles');
    const timeOfDay = startsAtNewYork.hour() >= 18 ? 'Night' : 'Day';
    const formattedDate = startsAtNewYork.format('M/D');
    return `${contest.league.toUpperCase()} ${timeOfDay} ${formattedDate}`;
  }, [contest]);

  const statusTime = useMemo(() => {
    if (!contest) return '';
    return getEventStatusString(contest);
  }, [contest]);

  const status = useMemo(() => {
    if (!contest) return '';
    if (contest.status === 'active') {
      return 'live';
    }
    if (contest.status === 'completed') {
      return 'completed ✓';
    }
    return contest.status;
  }, [contest]);

  const onButtonClick = (location: string, message: string | undefined) => {
    ReactGA.event({
      category: 'Contest',
      action: 'click',
      label: message,
    });

    navigate(location);
  };

  return (
    <Box display="flex" justifyContent="center" flexDir="column" {...props}>
      <Box textAlign="center">
        <Img mx="auto" src={contest.image} width="160px" />
        <Box
          textTransform="lowercase"
          fontSize="13px"
          fontWeight="800"
          lineHeight="16px"
          mt="8px"
          color={contest.status === 'active' || contest.status === 'completed' ? '#F83C3C' : 'white'}
        >
          {status}
        </Box>
        <Box fontSize="23px" fontWeight="800" lineHeight="27px" mt="4px">
          {title}
        </Box>
        <Box fontSize="15px" fontWeight="800" lineHeight="18px" mt="8px">
          {statusTime}
        </Box>
        <Flex justify="center" mt="23px">
          <ContestButton contest={contest} onButtonClick={onButtonClick} />
        </Flex>
      </Box>
    </Box>
  );
};

export default SliderItem;

const ContestButton = ({
  contest,
  onButtonClick,
}: {
  contest: Contest;
  onButtonClick: (location: string, message: string | undefined) => void;
}) => {
  const [trigger, { data: participant, isLoading }] = useLazyGetContestParticipantQuery();

  useEffect(() => {
    if (contest.id && (contest.status === 'active' || contest.status === 'locked')) {
      trigger(contest.id);
    }
  }, []);

  const resolveButtonText = (status: string) => {
    if (status === 'completed') {
      return 'leaderboard';
    }
    if (status === 'scheduled') {
      return 'build squad';
    }
    if (status === 'locked') {
      if (participant?.status === 'completion_pending' || participant?.status === 'completed') {
        return 'my squad';
      } else {
        return 'leaderboard';
      }
    }
    if (status === 'active') {
      if (participant?.status === 'cards_pending') {
        return 'finish squad';
      } else if (participant?.status === 'completion_pending') {
        return 'finish squad';
      } else if (participant?.status === 'completed') {
        return 'my squad';
      } else {
        return 'build squad';
      }
    }
  };

  const getButtonProps = () => {
    switch (contest.status) {
      case 'completed':
        return {
          text: resolveButtonText(contest.status),
          onClick: () => onButtonClick(`${routes.leaderboard}?contestId=${contest.id}`, 'Leaderboard click'),
          isDisabled: false,
          dataCy: 'completed-contest-btn',
        };
      case 'scheduled':
        return {
          text: resolveButtonText(contest.status),
          onClick: undefined,
          isDisabled: true,
          dataCy: undefined,
        };
      case 'locked':
        return {
          text: resolveButtonText(contest.status),
          onClick: () => onButtonClick(routes.squad, 'My Squad Click On Locked Contest'),
          isDisabled: false,
          dataCy: 'locked-contest-btn',
        };
      case 'active':
        return {
          text: resolveButtonText(contest.status),
          onClick: () => onButtonClick(routes.squad, 'My Squad Click On Active Contest'),
          isDisabled: false,
          dataCy: 'active-contest-btn',
        };
      default:
        return {
          text: '',
          onClick: undefined,
          isDisabled: true,
          dataCy: undefined,
        };
    }
  };

  const { text, onClick, isDisabled, dataCy } = useMemo(getButtonProps, [contest, participant]);

  return (
    <Button w="205px" onClick={onClick} isDisabled={isDisabled || isLoading} data-cy={dataCy} isLoading={isLoading}>
      {text}
    </Button>
  );
};
